import _ from 'lodash';
import {
  State, City, Country
} from 'country-state-city';

/**
 * Return cities for city dropdowns
 * @param  {string} countryCode of selected country
 * @return {Array} of city options {label, value}
 */
export const getCitiesOfACountry = (countryCode) => {
  if (!countryCode) return;

  let cities = State.getStatesOfCountry(countryCode);

  if (!_.isEmpty(cities)) {
    return _.map(cities, ({name, isoCode}) => {
      return {value: isoCode, label: name};
    });
  }

  cities = City.getCitiesOfCountry(countryCode);
  if (!_.isEmpty(cities)) {
    return _.map(cities, ({name, stateCode}) => {
      return {value: stateCode, label: name};
    });
  }

  return [{value: countryCode, label: Country.getCountryByCode(countryCode)?.name || countryCode}];
};

/**
 * Return cities by code
 * @param  {string} countryCode of city
 * @param  {string} cityCode of city
 * @return {String} representing the city
 */
export const getCityByCode = (countryCode, cityCode) => {
  if (!countryCode) return cityCode;
  const cities = getCitiesOfACountry(countryCode);
  return _.find(cities, (city) => city.value === cityCode)?.label || cityCode;
};
